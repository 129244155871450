import React from 'react'
import './App.css'
import RouterView from './router'



function App() {
  return <RouterView></RouterView>
}

export default App