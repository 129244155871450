import React, { Suspense } from 'react'
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom'
import { routes } from './routes'


/* Use components to define routes */
const RouterView = () => (
  <HashRouter basename="/react-cli">
    <Suspense>
      <Switch>
        {routes.map(route => (
          <Route key={route.path} path={route.path} component={route.component} exact={route.exact}></Route>
        ))}
        <Redirect to="/property"></Redirect>
        {/* <Redirect to="/card"></Redirect> */}
      </Switch>
    </Suspense>
  </HashRouter>
)
export default RouterView
