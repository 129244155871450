import { lazy } from "react";

const Property = lazy(
  () => import("../pages/property")
);
const MyAccount = lazy(
  () => import("../pages/myAccount")
);
const Balance = lazy(
  () => import("../pages/balance")
);
const WalletWithdrawal = lazy(
  () => import("../pages/walletWithdrawal")
);
const WithdrawalRecord = lazy(
  () => import("../pages/withdrawalRecord")
);
const WithdrawalDetails = lazy(
  () => import("../pages/withdrawalDetails")
);
const Statements = lazy(
  () => import("../pages/statements")
);
const StatementsDetail = lazy(
  () => import("../pages/statementsDetail")
);

const StatementsDetails = lazy(
  () => import("../pages/statementsDetails")
);
const Credit = lazy(
  () => import("../pages/credit")
);
const Login = lazy(
  () => import("../pages/login")
);
const Reimbursement = lazy(
  () => import("../pages/reimbursement")
);
const SettlementRecords = lazy(
  () => import("../pages/settlementRecords")
);
const SettlementRecordsDetail = lazy(
  () => import("../pages/settlementRecordsDetail")
);
const BillingRecords = lazy(
  () => import("../pages/billingRecords")
);
const ReimbursementRecord = lazy(
  () => import("../pages/reimbursementRecord")
);
const SeTupThe = lazy(
  () => import("../pages/seTupThe")
);
const SeTupTheAdd = lazy(
  () => import("../pages/seTupTheAdd")
);
const DoNotUse = lazy(
  () => import("../pages/doNotUse")
);
const RelationalRecord = lazy(
  () => import("../pages/relationalRecord")
);

const QianbaoBalance = lazy(
  () => import("../pages/qianbaoBalance")
);
const BindingCard = lazy(
  () => import("../pages/bindingCard")
);
const QianbaoWalletWithdrawal = lazy(
  () => import("../pages/qianbaoWalletWithdrawal")
);
const Card = lazy(
  () => import("../pages/card")
);
const CardDetail = lazy(
  () => import("../pages/cardDetail")
);
const CardCertification1 = lazy(
  () => import("../pages/cardCertification1/index.tsx")
);
const PersonalDetails = lazy(
  () => import("../pages/personalDetails/index.tsx")
);
const PersonalBasicInformation = lazy(
  () => import("../pages/personalBasicInformation/index.tsx")
);
const PersonalInformationPreview = lazy(
  () => import("../pages/personalInformationPreview/index.tsx")
);
const SubjectInformation = lazy(
  () => import("../pages/subjectInformation/index.tsx")
);
const LegalPerson = lazy(
  () => import("../pages/legalPerson/index.tsx")
);
const BasicInformationEnterprise = lazy(
  () => import("../pages/basicInformationEnterprise/index.tsx")
);
const PersonalPreview = lazy(
  () => import("../pages/personalPreview/index.tsx")
);
const Recharge = lazy(
  () => import("../pages/recharge/index.tsx")
);
const RechargeRecord = lazy(
  () => import("../pages/rechargeRecord/index")
);
const Marketing = lazy(
  () => import("../pages/marketing/index")
);
const AgreeOn = lazy(
  () => import("../pages/agreeOn/index")
);
const SettlementRecordsDetailDetail = lazy(
  () => import("../pages/settlementRecordsDetailDetail/index")
);

export const routes = [

  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/billingRecords",
    component: BillingRecords,
    exact: true,
  },
  {
    path: "/settlementRecords",
    component: SettlementRecords,
    exact: true,
  },
  {
    path: "/settlementRecordsDetail",
    component: SettlementRecordsDetail,
    exact: true,
  },

  {
    path: "/statementsDetail",
    component: StatementsDetail,
    exact: true,
  },
  {
    path: "/reimbursement",
    component: Reimbursement,
    exact: true,
  },
  {
    path: "/reimbursementRecord",
    component: ReimbursementRecord,
    exact: true,
  },
  {
    path: "/doNotUse",
    component: DoNotUse,
    exact: true,
  },
  {
    path: "/relationalRecord",
    component: RelationalRecord,
    exact: true,
  },

  {
    path: "/statementsDetails",
    component: StatementsDetails,
    exact: true,
  },
  {
    path: "/credit",
    component: Credit,
    exact: true,
  },

  {
    path: "/property",
    component: Property,
    exact: true,
  },
  {
    path: "/balance",
    component: Balance,
    exact: true,
  },
  {
    path: "/qianbaoBalance",
    component: QianbaoBalance,
    exact: true,
  },

  {
    path: "/walletWithdrawal",
    component: WalletWithdrawal,
    exact: true,
  },
  {
    path: "/withdrawalRecord",
    component: WithdrawalRecord,
    exact: true,
  },
  {
    path: "/withdrawalDetails",
    component: WithdrawalDetails,
    exact: true,
  },

  {
    path: "/statements",
    component: Statements,
    exact: true,
  },

  {
    path: "/myAccount",
    component: MyAccount,
    exact: true,
  },
  {
    path: "/seTupThe",
    component: SeTupThe,
    exact: true,
  },
  {
    path: "/seTupTheAdd",
    component: SeTupTheAdd,
    exact: true,
  },
  {
    path: "/bindingCard",
    component: BindingCard,
    exact: true,
  },
  {
    path: "/qianbaoWalletWithdrawal",
    component: QianbaoWalletWithdrawal,
    exact: true,
  },
  {
    path: "/card",
    component: Card,
    exact: true,
  },
  {
    path: "/cardDetail",
    component: CardDetail,
    exact: true,
  },
  {
    path: "/cardCertification1",
    component: CardCertification1,
    exact: true,
  },
  {
    path: "/personalDetails",
    component: PersonalDetails,
    exact: true,
  },
  {
    path: "/personalBasicInformation",
    component: PersonalBasicInformation,
    exact: true,
  },
  {
    path: "/personalInformationPreview",
    component: PersonalInformationPreview,
    exact: true,
  },
  {
    path: "/subjectInformation",
    component: SubjectInformation,
    exact: true,
  },
  {
    path: "/legalPerson",
    component: LegalPerson,
    exact: true,
  },
  {
    path: "/basicInformationEnterprise",
    component: BasicInformationEnterprise,
    exact: true,
  },
  {
    path: "/personalPreview",
    component: PersonalPreview,
    exact: true,
  },
  {
    path: "/recharge",
    component: Recharge,
    exact: true,
  },
  {
    path: "/rechargeRecord",
    component: RechargeRecord,
    exact: true,
  },
  {
    path: "/marketing",
    component: Marketing,
    exact: true,
  },
  {
    path: "/agreeOn",
    component: AgreeOn,
    exact: true,
  },
  {
    path: "/settlementRecordsDetailDetail",
    component: SettlementRecordsDetailDetail,
    exact: true,
  },
  
];
